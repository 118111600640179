body{
    font-family: Verdana, Helvetica, sans-serif;
    background-color: #CDCDCD;
}

.navbarbrandcollapsed{
    height: 2em;
}


.textTopbar{
    font-size: 20pt;
}

@media screen and (max-width: 363px){
    .navbreak{
        display: none;
    }
}

.navbreak2{
    display: none;
}

.navbreak3{
    display: none;
}

@media screen and (max-width: 342px){
    .navbreak2{
        display: none !important;

    }
    div .navbreak3{
        display: block;
    }
}
.gray{
    color: #717274;
}

#navbarcollapsed{
    padding-top: 0rem;
    padding-bottom: 0rem;
}
.navbar{
    background-color: #ffffff;
    border-bottom-width: 4.5pt;
    border-bottom-color: #006bb3;
    border-bottom-style: solid;
    padding-right: 0.1rem;
    padding-left: 1rem;
}

.green{
    color: #41a929;
}

.blue{
    color: #006bb3
}

.buttonexpand{
    border-top-width: 1px;
    border-top-color: #717274;
    border-top-style: solid;
    text-align: right;
    color: #006bb3;
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: #ffffff;
}

.buttonexpand:hover{
    color: #41a929;
}


.card-header{
    background-color: #006bb3;
    color: #ffffff;
    font-size: 22pt;
    padding: 0.25rem 1.25rem;
    margin: 0rem;
}

.card{
    border-bottom-width: 4.5pt;
    border-bottom-color: #006bb3;
    border-bottom-style: solid;
    margin-right: 0rem;
}
.only-img{
    border-bottom: none;
}

div.card-header.modal-header{
    padding-right: 1.25rem !important;
    margin-right: 0rem !important;

}

.modal-body{
    border-bottom-width: 4.5pt;
    border-bottom-color: #006bb3;
    border-bottom-style: solid;
}

.modal-header button.close{
    padding-bottom: 0rem;
}
.card-body{
    color: #717274;
}


.img-thumbnail{
    width: 15rem;
}

.card-body-multimg{
    padding: 0.8rem;
    padding-bottom: 0rem;
    min-height: 8.5rem;
}

.img-overlay{
    max-width: 100%;
    margin-bottom: 1rem;
}

.in-text-img{
    max-width: 25%;
}

.modal-body{
    color: #717274;
}

.navbar-nav a.nav-link{
    color: #006bb3 !important;
    text-decoration: none;
}

.navbar-nav a.nav-link:hover{
    color: #41a929 !important;
    text-decoration: none;
}

.navbar-nav a.active{
    color: #41a929 !important;
    text-decoration: none;
}


@media screen and (max-width: 576px){
    .card-columns{
        column-count: 1;
        margin-top: 7rem;
    }
    div .navbreak2{
        display: block;
    }
}


@media screen and (min-width: 576px){
    .card-columns{
        column-count: 1;
        margin-top: 5rem;

    }
    /* .navbreak{
        display: none;
    } */

}


@media screen and (min-width: 768px){
    .card-columns{
        column-count: 2;
        margin-top: 5rem;

    }
    .navbar-nav{
        display: none;
    }
}

@media screen and (min-width: 992px){
    .card-columns{
        column-count: 3;

    }
}

.navbar-toggler-icon{
    color: #717274;
}

.navbar-toggler{
    color: #717274;
    padding: 0.16666rem 0.5rem;
    border: 0rem;
}


a.nav-link{
    border-top-style: solid;
    border-width: 1px;
    border-color: #717274;
}

ul{
    margin-top: 1rem;
}

.card-body p{
    margin-bottom: 0rem;
}

.accordionCardBody.card-body p{
    margin-bottom: 1rem;
}

.accordionCardBody.card-body{
    border-bottom-width: 4.5pt;
    border-bottom-color: #006bb3;
    border-bottom-style: solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.accordionCard:last-child{
    border-bottom-color:  rgba(0, 0, 0, 0.125);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-width: 1px;
}

.col-9 p{
    margin-bottom: 0.5rem;
}

.close{
    color: #ffffff;
}

.close:hover{
    color: #ffffff;
}

@media screen and (min-width: 1200px){
    .container{
        max-width: 1400px;
    }
}

.modal{
    padding-left: 0rem !important;
}


.navbar-nav{
    padding-right: 1rem;
}

.modal-dialog{
    margin-top: 0rem;
}

.modal-content{
    border-top-width: 0rem;
}

a.link-more span{
    font-size: 12pt;
}

a.link-more:hover{
    color: #41a929;
    text-decoration: none;
}

a.link-more{
    color: #006bb3;
    cursor: pointer;
}

.linked{
    cursor: pointer;
}

.AccordionHeader.collapsed{
    background-color: #ffffff;
    border-bottom: none;
    color: #717274;
}

.AccordionHeader{
    font-size: 17pt;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url(http://localhost:3000/assets/font/material-icons.woff2) format('woff2'),
      url(http://localhost:3000/assets/font/material-icons.woff) format('woff')
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  